import * as React from "react";
import * as Canvas from "canvas";

import headerBlack from "images/header-black.png";
import headerWhite from "images/header-white.png";

// padding: ${(props) =>
//   Style.Snippets.responsivePadding(props.theme.device, "40px", "50px")};

import * as Styled from "./bsu.styled";

export const BSU = () => {
  const [imgURL, setImgURL] = React.useState("");
  const [imgName, setImgName] = React.useState("twitter-bsu");
  const [color, setColor] = React.useState("white");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [text, setText] = React.useState(
    "Type something into the form to replace this text."
  );
  const [textHeight, setTextHeight] = React.useState(0);
  const [evenMargin, setEvenMargin] = React.useState(0);

  const linkButtonRef = React.useRef(null);

  const bsuWidth = 2160;
  const bsuHeight = 2700;

  const headerWidth = 2160;
  const headerHeight = 407;

  const canvas = Canvas.createCanvas(bsuWidth, bsuHeight);
  const ctx = canvas.getContext("2d");

  // Drawing the BSU downloadable image in .jpeg format.
  Canvas.loadImage(color === "white" ? headerBlack : headerWhite)
    .then((image: any) => {
      // Drawing background color.
      ctx.beginPath();
      ctx.rect(0, 0, bsuWidth, bsuHeight);
      ctx.fillStyle = color === "white" ? "black" : "white";
      ctx.fill();

      // Drawing the image.
      ctx.drawImage(image, 0, evenMargin, headerWidth, headerHeight);

      // Drawing text on top of boilerplate BSU image.
      ctx.font = "120px roboto-regular";
      ctx.textBaseline = "middle";
      ctx.fillStyle = `${color}`;
      const words = text.split(" ");
      const textMaxWidth = 1920;
      const lineHeight = 150;
      const xOffset = 120;
      let yOffset = evenMargin + headerHeight + 65;
      let line = "";
      let numberLines = 1;

      // Logic for putting overflowing text onto next line, as canvas doesn't have
      // that feature built in.
      for (let i = 0; i < words.length; i++) {
        const currentTextLine = line + words[i] + " ";
        const textMetrics = ctx.measureText(currentTextLine);
        const textWidth = textMetrics.width;
        if (textWidth > textMaxWidth && i > 0) {
          ctx.fillText(line, xOffset, yOffset);
          line = words[i] + " ";
          yOffset += lineHeight;
          numberLines += 1;
        } else {
          line = currentTextLine;
        }
      }

      ctx.fillText(line, xOffset, yOffset);

      setTextHeight(120 * numberLines);

      const contentHeight = headerHeight + textHeight;
      setEvenMargin((bsuHeight - contentHeight) / 2);

      setImgURL(canvas.toDataURL("image/jpeg", 1));
    })
    .catch((error: string) => {
      setErrorMessage(
        `There was an error in retrieving the BSU boilerplate image: ${error}`
      );
    });

  function handleTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const userInput = event.currentTarget.value;
    setText(userInput);
  }

  function handleImageNameChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const userInput = event.currentTarget.value;
    setImgName(userInput);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
  }

  return (
    <Styled.BSUContainer>
      <BSUPreview url={imgURL} error={errorMessage} />
      <BSUForm
        linkButtonRef={linkButtonRef}
        handleSubmit={handleSubmit}
        handleTextChange={handleTextChange}
        handleImageNameChange={handleImageNameChange}
        imgURL={imgURL}
        imgName={imgName}
        setColor={setColor}
      />
    </Styled.BSUContainer>
  );
};

// ========================================= //
// ========================================= //
// ========================================= //

type BSUPreviewProps = {
  url: string;
  error: string;
};

const BSUPreview = (props: BSUPreviewProps) => {
  const { url, error } = props;

  return (
    <Styled.BSUPreviewContainer>
      <Styled.BSUPreviewImage src={url} alt="bsu" />
      <Styled.BSUErrorMessage>{error}</Styled.BSUErrorMessage>
    </Styled.BSUPreviewContainer>
  );
};

// ========================================= //
// ========================================= //
// ========================================= //

type BSUFormProps = {
  linkButtonRef: any;

  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  imgURL: string;
  imgName: string;

  setColor: (color: string) => void;
};

const BSUForm = (props: BSUFormProps) => {
  const {
    linkButtonRef,
    handleSubmit,
    handleTextChange,
    handleImageNameChange,
    imgURL,
    imgName,
    setColor,
  } = props;

  function setColorBlack(): void {
    setColor("white");
  }

  function setColorWhite(): void {
    setColor("black");
  }

  return (
    <Styled.BSUFormContainer onSubmit={handleSubmit}>
      <Styled.BSUFormTitle>
        Gary Vee <br /> Twitter BSU Image Generator
      </Styled.BSUFormTitle>

      <Styled.BSUFormColorButtons>
        <Styled.BSUFormWhiteButton onClick={setColorBlack}>
          Black
        </Styled.BSUFormWhiteButton>
        <Styled.BSUFormBlackButton onClick={setColorWhite}>
          White
        </Styled.BSUFormBlackButton>
      </Styled.BSUFormColorButtons>

      <Styled.BSUFormInputText onChange={handleTextChange} />
      <Styled.BSUFormInputImageName onChange={handleImageNameChange} />

      <Styled.BSUFormDownloadButton
        href={imgURL}
        download={imgName}
        ref={linkButtonRef}
      >
        Download
      </Styled.BSUFormDownloadButton>
    </Styled.BSUFormContainer>
  );
};
