import * as React from "react";

import * as Components from "components";

import * as Styled from "./index.styled";

export const Index = () => {
	return (
		<Styled.IndexContainer>
			<Components.BSU />
		</Styled.IndexContainer>
	);
};
