import { css } from "styled-components";

// ============== //
// ↓↓↓ Styles ↓↓↓ //
// ============== //

export function fillView() {
	return css`
		width: 100vw;
		height: 100vh;
	`;
}

export function fillContainer() {
	return css`
		width: 100%;
		height: 100%;
	`;
}

export function size(width: string, height: string) {
	return css`
		width: ${width};
		height: ${height};
	`;
}

export function square(size: number) {
	return css`
		width: ${size}px;
		height: ${size}px;
	`;
}

export function circle() {
	return css`
		border-radius: 50%;
	`;
}

export function flex(
	direction: string = "row",
	justifyContent: string = "auto",
	alignItems: string = "auto",
) {
	return css`
		display: flex;
		flex-direction: ${direction};
		justify-content: ${justifyContent};
		align-items: ${alignItems};
	`;
}

export function grid(
	columnRepeat: number = 1,
	columnSize: string = "auto",
	gap: number = 0,
	justifyItems: string = "auto",
	alignItems: string = "auto",
) {
	return css`
		display: grid;
		grid-template-columns: repeat(${columnRepeat}, ${columnSize});
		grid-gap: ${gap}px;
		justify-items: ${justifyItems};
		align-items: ${alignItems};
	`;
}

export function position(
	position: string = "static",
	top: string = "auto",
	right: string = "auto",
	bottom: string = "auto",
	left: string = "auto",
	zIndex: number = 1,
) {
	return css`
		position: ${position};
		top: ${top};
		right: ${right};
		bottom: ${bottom};
		left: ${left};
		z-index: ${zIndex};
	`;
}

export function absolute(
	top: string = "auto",
	right: string = "auto",
	bottom: string = "auto",
	left: string = "auto",
	zIndex: number = 1,
) {
	return css`
		position: absolute;
		top: ${top};
		right: ${right};
		bottom: ${bottom};
		left: ${left};
		z-index: ${zIndex};
	`;
}

export function fixed(
	top: string = "auto",
	right: string = "auto",
	bottom: string = "auto",
	left: string = "auto",
	zIndex: number = 1,
) {
	return css`
		position: fixed;
		top: ${top};
		right: ${right};
		bottom: ${bottom};
		left: ${left};
		z-index: ${zIndex};
	`;
}

export function absoluteCentered() {
	return css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	`;
}

export function hideScrollbar() {
	return css`
		-ms-overflow-style: none;

		::-webkit-scrollbar {
			display: none;
		}
	`;
}

export function noTextHighlight() {
	return css`
		.noselect {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
		}
	`;
}

export function debug(color: string, type: string, size: number) {
	return css`
		border: ${color} ${type} ${size}px;
	`;
}
