import styled from "styled-components";

import * as Snippets from "utils/snippets";

export const BSUContainer = styled("div")`
  ${Snippets.fillView()};
  ${Snippets.flex()};
  background-color: #4f4f4f;
`;

export const BSUPreviewContainer = styled("div")`
  position: relative;
  flex: 2;
`;

export const BSUPreviewImage = styled("img")`
  ${Snippets.absoluteCentered()};
  ${Snippets.size("auto", "95%")};
`;

export const BSUErrorMessage = styled("div")`
  color: red;
  font-size: 2rem;
`;

export const BSUFormContainer = styled("form")`
  ${Snippets.flex("column", "auto", "auto")};
  flex: 1;
  padding: 0% 2%;
  background-color: #3d3b3d;
`;

export const BSUFormTitle = styled("h1")`
  margin-top: 40px;
  color: #e8e8e8;
  font-size: 2rem;
  text-align: center;
  line-height: 140%;
`;

export const BSUFormDirections = styled("ul")`
  margin-bottom: 40px;
  color: #e8e8e8;
  font-size: 1.3rem;
  line-height: 200%;
`;

export const BSUFormColorButtons = styled("div")`
  ${Snippets.grid(2, "1fr", 20)};
  ${Snippets.size("90%", "4%")};
  margin: 0 auto 3%;
`;

export const BSUFormWhiteButton = styled("div")`
  ${Snippets.flex("row", "center", "center")};
  ${Snippets.fillContainer()};
  flex: 1;
  color: #e8e8e8;
  background-color: #262626;
  border-radius: 0.2rem;
  cursor: pointer;
`;

export const BSUFormBlackButton = styled(BSUFormWhiteButton)``;

export const BSUFormInputText = styled("input").attrs(() => ({
  placeholder: "Type your text here.",
}))`
  ${Snippets.size("90%", "4%")};
  margin: 0px auto 3%;
  padding: 2%;
  color: #262626;
  background-color: #e8e8e8;
  border: #e8e8e8 solid 1px;
  border-radius: 0.2rem;
  font-size: 1rem;
  outline: none;

  ::placeholder {
    color: #c4c4c4;
  }
`;

export const BSUFormInputImageName = styled(BSUFormInputText).attrs(() => ({
  placeholder: "Create the file name here.",
}))``;

export const BSUFormDownloadButton = styled("a")`
  ${Snippets.flex("row", "center", "center")};
  ${Snippets.size("90%", "4%")};
  display: block;
  margin: 0px auto;
  color: #262626;
  background-color: #e8e8e8;
  border: #e8e8e8 solid 1px;
  border-radius: 0.2rem;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  line-height: 230%;
  transition: 0.1s ease-in-out;
  outline: none;
  cursor: pointer;

  :hover {
    color: #e8e8e8;
    background-color: #262626;
    border: #262626 solid 1px;
  }

  :active {
    transform: translateY(4px);
  }
`;
